import React, { useEffect, useState } from 'react';
import '../Screens/registration.css'; // Make sure to include your CSS file
import bcrypt from 'bcryptjs';
import { useNavigate } from 'react-router-dom';


const RegistrationPage=()=> {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    category: '',
    name: '',
    phone: '',
    email: '',
    businessName: '',
    businessAddress: '',
    password: '',
    confirmPassword: '',
  });
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({}); // State to hold validation errors

  useEffect(() => {
    fetch('https://www.tietheday.com/getCategories.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setCategories(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: '' }); // Clear error when user types
  };

  const validateStep = () => {
    let newErrors = {};
    let isValid = true;

    // Validation for Section 1
    if (step === 1 && !formData.category) {
      newErrors.category = 'Please select a category.';
      isValid = false;
    }

    // Validation for Section 2
    if (step === 2) {
      if (!formData.name) {
        newErrors.name = 'Name is required.';
        isValid = false;
      }
      if (!formData.phone) {
        newErrors.phone = 'Phone number is required.';
        isValid = false;
      } else if (!/^\d{10}$/.test(formData.phone)) {
        newErrors.phone = 'Phone number must be 10 digits long and numeric.';
        isValid = false;
      }
      if (!formData.email) {
        newErrors.email = 'Email is required.';
        isValid = false;
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = 'Email must be valid.';
        isValid = false;
      }
    }

    // Validation for Section 3
    if (step === 3) {
      if (!formData.businessName) {
        newErrors.businessName = 'Business name is required.';
        isValid = false;
      }
      if (!formData.businessAddress) {
        newErrors.businessAddress = 'Business address is required.';
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const nextStep = () => {
    if (validateStep()) {
      setStep(step + 1);
    }
  };

  const prevStep = () => setStep(step - 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateStep()) {
      console.log('Form submitted:', formData);
      // Handle actual submission logic here
    }
  };
  const handleFinalSubmit = async (e) => {
    e.preventDefault();
    if (validateStep()) {
      try {
        // Hash the password before sending (use bcryptjs or a similar library)
        const hashedPassword = await bcrypt.hash(formData.password, 10);
        console.log('Name',formData.name);
        console.log('Mobile',formData.phone);
        console.log('Email',formData.email);
        console.log('Password',hashedPassword);
        console.log('buisness_name',formData.businessName);
        console.log('buisness_address',formData.businessAddress);
  
        const response = await fetch('https://www.tietheday.com/registerPartner.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },

          
          body: JSON.stringify({
            name: formData.name,
            mobile: formData.phone,
            email: formData.email,
            password: hashedPassword,
            business_name: formData.businessName,
            business_address: formData.businessAddress,
          }),
        });
  
        if (!response.ok) {
          throw new Error(response.error);
        }
        
        const data = await response.json();
        if (response.ok) {
          if (data.code === 1) {
            alert(data.message); // Show success message
            navigate('/welcome'); // Navigate to WelcomePage
          } else {
            alert(data.message); // Show error message
          }
          
        } else {
          console.error('Error during registration:', data.message);
          alert(data.error); // Show error message
        }
        // Handle successful registration (e.g., redirect or show a success message)
      } catch (error) {
        console.error('Error during registration:', error);
        alert(error);
        // Handle error (e.g., show error message)
      }
    }
  };
  

  if (loading) {
    return <p>Loading categories...</p>;
  }

  return (
    <div className="register-partner-container">
      <h1>Partner Registration</h1>
      <form className="register-partner-form" onSubmit={handleSubmit}>
        {/* Section 1: Select Category */}
        {step === 1 && (
          <div className="form-section">
            <div className="form-group">
              <label htmlFor="category">Category:</label>
              <select
                id="category"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
              {errors.category && <span className="error">{errors.category}</span>}
            </div>
            <button type="button" className="next-btn" onClick={nextStep}>
              Next
            </button>
          </div>
        )}

        {/* Section 2: Personal Information */}
        {step === 2 && (
          <div className="form-section">
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <input
                type="text"
                id="phone"
                placeholder="Enter your phone number"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>

            <button type="button" className="prev-btn" onClick={prevStep}>
              Previous
            </button>
            <button type="button" className="next-btn" onClick={nextStep}>
              Next
            </button>
          </div>
        )}

        {/* Section 3: Business Information */}
        {step === 3 && (
          <div className="form-section">
            <div className="form-group">
              <label htmlFor="businessName">Business Name:</label>
              <input
                type="text"
                id="businessName"
                placeholder="Enter your business name"
                value={formData.businessName}
                onChange={handleChange}
                required
              />
              {errors.businessName && <span className="error">{errors.businessName}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="businessAddress">Business Address:</label>
              <input
                type="text"
                id="businessAddress"
                placeholder="Enter your business address"
                value={formData.businessAddress}
                onChange={handleChange}
                required
              />
              {errors.businessAddress && <span className="error">{errors.businessAddress}</span>}
            </div>

            <button type="button" className="prev-btn" onClick={prevStep}>
              Previous
            </button>
            <button type="button" className="next-btn" onClick={nextStep}>
              Next
            </button>
          </div>
        )}

        {/* Section 4: Password Setup */}
        {step === 4 && (
          <div className="form-section">
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                placeholder="Enter your password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type="password"
                id="confirmPassword"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>

            <button type="button" className="prev-btn" onClick={prevStep}>
              Previous
            </button>
            <button type="submit" className="submit-btn" onClick={handleFinalSubmit}>
              Submit
            </button>
          </div>
        )}
      </form>
    </div>
  );
}

export default RegistrationPage;
