// WelcomePage.js
import React, { useState } from "react";
import { auth, } from "../Utilities/firebaseconfig";
import { RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword } from "firebase/auth";
import "../Screens/welcomepage.css";
import firebase from "firebase/compat/app";
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setLogin } from '../Utilities/authSlice';
// import { useNavigate } from 'react-router-dom';




const WelcomePage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const[verificationId,setVerificationId]=useState("")
  const [error, setError] = useState(null);

  const navigate = useNavigate();


  const dispatch = useDispatch();
  // const history = useNavigate();


  // firebase.auth().settings.appVerificationDisabledForTesting = true;


  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      if(!email){
        alert("Email is required")
      }else if(!password){
        alert("Password is required")
      }else{
        try {
          // Hash the password before sending (use bcryptjs or a similar library)
          // const hashedPassword = await bcrypt.hash(password, 10);
        console.log("Email",email);
        console.log("Password",password);  
    
          const response = await fetch('https://www.tietheday.com/login.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
  
            
            body: JSON.stringify({
              email: email,
              password: password,
              
            }),
          });
    
          if (!response.ok) {
            throw new Error(response.error);
          }
          
          const data = await response.json();
          if (response.ok) {
            if (data.code === 1) {
              alert(data.message); // Show success message
              console.log("Login is very very successful");

              dispatch(setLogin({ userId: data.userId, name: data.name, email: data.email,mobile:data.mobile }));
              navigate('/dashboard');
              
            } else {
              alert(data.message); // Show error message
            }
            
          } else {
            console.error('Error in login:', data.message);
            alert(data.message); // Show error message
          }
          // Handle successful registration (e.g., redirect or show a success message)
        } catch (error) {
          console.error('Error in login:', error);
          alert(error);
          // Handle error (e.g., show error message)
        }

      }
    } catch (error) {
      alert(error.message);
    }
  };
  function onCaptchaVerify() {
    if (!window.recaptchaVerifier) {
      console.log('Wihvhhvjf','1');
      console.log("authentication",''+auth==null);
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'normal',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSendOTP();
        }
      });
    }else{
      console.log('Wihvhhvjf','0');
    }
  }
  function onSendOTP(){
    console.log("Cursor","Sending OTP2345679y3")
  }

 

  const sendOtp = (e) => {
    console.log("Cursor","Send otp");
    e.preventDefault();
    const mobileNumberWithCode = `+91${phone}`;

    onCaptchaVerify();
    console.log("Cursor","Send otp123");
    const appVerifier = window.recaptchaVerifier;


   

    signInWithPhoneNumber(auth, mobileNumberWithCode, appVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        console.log('OTP sent');
      })
      .catch((error) => {
        setError('Failed to send OTP');
        console.error('Error sending OTP', error);
      });
  };
  

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    try {
      await window.confirmationResult.confirm(otp);
      alert("Phone Authentication Successful");
    } catch (error) {
      alert("Invalid OTP");
    }
  };

  return (
    <div className="welcome-container">
      <div className="login-box">
        <h1>Tie The Day Partners</h1>
        <p>Connecting you to unforgettable events</p>
        <form onSubmit={handleEmailLogin}>
          <h3>Email Login</h3>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" onClick={handleEmailLogin}>Login with Email</button>
        </form>

        {/* <div className="divider">OR</div>

        <form onSubmit={sendOtp}>
          <h3>Mobile Login</h3>
          <input
            type="tel"
            placeholder="Mobile Number (+91xxxxxxxxxx)"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <button type="submit">Send OTP</button>
        </form>

        {isOtpSent && (
          <form onSubmit={handleVerifyOtp}>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <button type="submit">Verify OTP</button>
          </form>
        )}

        <div id="recaptcha-container"></div> */}

        <p className="register-text">
          New Partner? <a href="/register">Register Here</a>
        </p>
      </div>
    </div>
  );
};

export default WelcomePage;
