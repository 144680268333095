// src/components/Header.js
import React from 'react';
import './Header.css'; // We'll create this CSS file next
import logo from '../Components/Images/ttd_logo.png'

const Header = () => {
  return (
    <header className="header">
      <div className="header__logo">
        <img src={logo} alt="Tie The Day" /> {/* Replace with your logo path */}
      </div>
      <nav className="header__nav">
        <ul className="header__navList">
          <li><a className='active' href="/">Home</a></li>
          <li><a href="/orders">Orders</a></li>
          <li><a href="/transactions">Transactions</a></li>
          <li><a href="/profile">Profile</a></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
