// src/components/Orders.js
import React, { useState } from 'react';
import './Orders.css';

const ordersData = [
  { id: 12345, customer: 'John Doe', date: '12-Oct-24', total: 125.0, status: 'Confirmed' },
  { id: 12346, customer: 'Jane Smith', date: '11-Oct-24', total: 75.0, status: 'Delivered' },
  { id: 12347, customer: 'Mike Tyson', date: '10-Oct-24', total: 89.5, status: 'Canceled' },
  { id: 12348, customer: 'Susan Boyle', date: '09-Oct-24', total: 110.0, status: 'Pending' },
];

const Orders = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const filteredOrders = ordersData.filter((order) => {
    return (
      (order.customer.toLowerCase().includes(searchTerm.toLowerCase()) || 
      order.id.toString().includes(searchTerm)) &&
      (statusFilter === '' || order.status === statusFilter)
    );
  });

  return (
    <div className="orders">
      <h2>My Orders</h2>
      
      {/* Search and Filter */}
      <div className="orders__searchFilter">
        <input
          type="text"
          placeholder="Search orders..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
          <option value="">All Status</option>
          <option value="Confirmed">Confirmed</option>
          <option value="Delivered">Delivered</option>
          <option value="Canceled">Canceled</option>
          <option value="Pending">Pending</option>
        </select>
      </div>

      {/* Order List */}
      <table className="orders__table">
        <thead>
          <tr>
            <th>Order #</th>
            <th>Customer</th>
            <th>Date</th>
            <th>Total</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.length > 0 ? (
            filteredOrders.map((order) => (
              <tr key={order.id}>
                <td>{order.id}</td>
                <td>{order.customer}</td>
                <td>{order.date}</td>
                <td>${order.total.toFixed(2)}</td>
                <td className={`status ${order.status.toLowerCase()}`}>{order.status}</td>
                <td>
                  <button className="orders__btn">View Details</button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No orders found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Orders;
