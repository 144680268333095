import React from "react";
import { BrowserRouter as Router, Route, Navigate, Routes, useLocation } from "react-router-dom";
import WelcomePage from "./Screens/welcomepage";
import RegistrationPage from "./Screens/RegistrationPage";
import Dashboard from "./Screens/dashboard";
import Orders from "./Screens/order";
import Transactions from "./Screens/Transactions";
import { useSelector } from 'react-redux';
import "./App.css";
import Header from "./Screens/Header";
import Profile from "./Screens/Profile";

const App = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const location = useLocation();

  // Array of paths where the header should not be shown
  const noHeaderPaths = ['/welcome', '/register'];

  return (
    <div className="App">
      {/* Conditionally render the Header based on the current route */}
      {!noHeaderPaths.includes(location.pathname) && <Header />}
      <Routes>
        <Route path="/welcome" element={isLoggedIn ? <Navigate to="/dashboard" /> : <WelcomePage />} />
        <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/welcome" />} />
        <Route path="/" element={<Navigate to={isLoggedIn ? "/dashboard" : "/welcome"} />} />
        <Route path="/orders" element={<Orders/>} />
        <Route path="/transactions" element={<Transactions/>} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path='/profile' element={<Profile/>}/>
      </Routes>
    </div>
  );
};

// This component wraps your App in a Router
const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
