import "./home.css"
const home= ()=>{




    return(
      <div className="Home_main">
        <div className="Search">
        <input
          type="text"
          className="Search_input"
          placeholder="Search..."
        />
        <button className="Search_button">Search</button>

        </div>
        <div className="Home_horizontal_container">
            <div className="Home_element_container">
                <h3>Profile Visit</h3>
                <hr/>
                <div className="home_value">
                    <p className="value">No Profile Visit Yet</p>
                </div>
            </div>

            <div className="Home_element_container">
                <h3>Enquiries</h3>
                <hr/>
                <div className="home_value">
                    <p className="value">No Enquiries Made </p>
                </div>
            </div>
            <div className="Home_element_container">
                <h3>Orders</h3>
                <hr/>
                <div className="home_value">
                    <p className="value">No Orders Booked </p>
                </div>
            </div>
            <div className="Home_element_container">
                <h3>Income</h3>
                <hr/>
                <div className="home_value">
                    <p className="value">No Income Earned </p>
                </div>
            </div>
        </div>

      </div>  
    );
}

export default home;