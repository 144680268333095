import React, { useState } from 'react';
import './Profile.css'; // Add necessary styling

const Profile = () => {
  // State to manage the general details
  const [details, setDetails] = useState({
    businessName: 'Vendor Name',
    ownerName: 'Owner Name',
    phone: '1234567890',
    email: 'owner@example.com',
    address: '123 Business St, City, Country',
    socialMedia: {
      facebook: 'facebook.com/vendor',
      instagram: 'instagram.com/vendor',
    },
    yearsInBusiness: 5,
    businessType: 'Individual',
    gstNumber: 'GST1234XYZ',
    panNumber: 'ABCDE1234F',
  });

  const [editing, setEditing] = useState(false);

  const [idProof, setIdProof] = useState(null);
  const [addressProof, setAddressProof] = useState(null);
  const [businessRegistration, setBusinessRegistration] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const handleSave = () => {
    setEditing(false);
    // Logic to save the details (API call, etc.)
    console.log('Saved details:', details);
    console.log('Uploaded ID Proof:', idProof);
    console.log('Uploaded Address Proof:', addressProof);
    console.log('Uploaded Business Registration:', businessRegistration);
  };

  const handleFileChange = (e, setter) => {
    const file = e.target.files[0];
    setter(file);
  };

  const handleCancel = () => {
    setEditing(false);
    // Reset to original values if needed
  };

  return (
    <div className="profile-general-details">
      <h2>General Details</h2>

      <form>
        <label>Business Name</label>
        <input
          type="text"
          name="businessName"
          value={details.businessName}
          onChange={handleChange}
          disabled={!editing}
        />

        <label>Owner/Contact Person's Name</label>
        <input
          type="text"
          name="ownerName"
          value={details.ownerName}
          onChange={handleChange}
          disabled={!editing}
        />

        <label>Phone Number</label>
        <input
          type="text"
          name="phone"
          value={details.phone}
          onChange={handleChange}
          disabled={!editing}
        />

        <label>Email</label>
        <input
          type="email"
          name="email"
          value={details.email}
          onChange={handleChange}
          disabled={!editing}
        />

        <label>Business Address</label>
        <input
          type="text"
          name="address"
          value={details.address}
          onChange={handleChange}
          disabled={!editing}
        />

        <label>Facebook</label>
        <input
          type="text"
          name="facebook"
          value={details.socialMedia.facebook}
          onChange={(e) =>
            setDetails({
              ...details,
              socialMedia: { ...details.socialMedia, facebook: e.target.value },
            })
          }
          disabled={!editing}
        />

        <label>Instagram</label>
        <input
          type="text"
          name="instagram"
          value={details.socialMedia.instagram}
          onChange={(e) =>
            setDetails({
              ...details,
              socialMedia: { ...details.socialMedia, instagram: e.target.value },
            })
          }
          disabled={!editing}
        />

        <label>Years in Business</label>
        <input
          type="number"
          name="yearsInBusiness"
          value={details.yearsInBusiness}
          onChange={handleChange}
          disabled={!editing}
        />

        <label>Business Type</label>
        <select
          name="businessType"
          value={details.businessType}
          onChange={handleChange}
          disabled={!editing}
        >
          <option value="Individual">Individual</option>
          <option value="Company">Company</option>
        </select>

        <label>GST Number</label>
        <input
          type="text"
          name="gstNumber"
          value={details.gstNumber}
          onChange={handleChange}
          disabled={!editing}
        />

        <label>PAN Number</label>
        <input
          type="text"
          name="panNumber"
          value={details.panNumber}
          onChange={handleChange}
          disabled={!editing}
        />
        <h3>Upload Documents</h3>
        <label>ID Proof (PAN/Aadhaar)</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, setIdProof)}
          disabled={!editing}
        />
        {idProof && <p>Uploaded: {idProof.name}</p>}

        <label>Address Proof (Utility Bill, Lease Agreement, etc.)</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, setAddressProof)}
          disabled={!editing}
        />
        {addressProof && <p>Uploaded: {addressProof.name}</p>}

        <label>Business Registration Certificate</label>
        <input
          type="file"
          onChange={(e) => handleFileChange(e, setBusinessRegistration)}
          disabled={!editing}
        />
        {businessRegistration && <p>Uploaded: {businessRegistration.name}</p>}
      </form>

      <div className="profile-buttons">
        {!editing ? (
          <button onClick={() => setEditing(true)}>Edit</button>
        ) : (
          <>
            <button onClick={handleSave}>Save</button>
            <button onClick={handleCancel}>Cancel</button>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
