import React, { useState, useEffect } from 'react';
import './Transactions.css';
import { ThreeDots } from 'react-loader-spinner'; // Importing the ThreeDots spinner
import Header from './Header';

const Transactions = () => {
  const initialTransactions = [
    { id: 'T001', date: '2024-10-01', amount: 1500, status: 'Completed' },
    { id: 'T002', date: '2024-10-05', amount: 2000, status: 'Pending' },
    { id: 'T003', date: '2024-10-07', amount: 2500, status: 'Failed' },
    { id: 'T004', date: '2024-10-10', amount: 1800, status: 'Completed' },
  ];

  const [filter, setFilter] = useState('All');
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false); // State to manage dropdown visibility

  useEffect(() => {
    const fetchData = () => {
      setTimeout(() => {
        setTransactions(initialTransactions);
        setLoading(false);
      }, 2000);
    };

    fetchData();
  }, []);

  const filteredTransactions = filter === 'All'
    ? transactions
    : transactions.filter(transaction => transaction.status === filter);

  const handleFilterChange = (status) => {
    setFilter(status);
    setDropdownVisible(false); // Hide dropdown after selecting an option
  };

  return (
    <div className="transactions">
        
      <h2>Transactions</h2>
      <div className="search">
        <input type="text" placeholder="Search transactions..." />
        <div className="dropdown-container">
          <button className="spinner-button" onClick={() => setDropdownVisible(!dropdownVisible)}>
            <ThreeDots color="#00BFFF" height={20} width={20} />
          </button>
          {dropdownVisible && (
            <div className="dropdown">
              <div onClick={() => handleFilterChange('All')}>All</div>
              <div onClick={() => handleFilterChange('Completed')}>Completed</div>
              <div onClick={() => handleFilterChange('Pending')}>Pending</div>
              <div onClick={() => handleFilterChange('Failed')}>Failed</div>
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <div className="loading-spinner">
          <ThreeDots color="#00BFFF" height={80} width={80} />
        </div>
      ) : (
        <table className="transactions__table">
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map((transaction) => (
              <tr key={transaction.id}>
                <td>{transaction.id}</td>
                <td>{transaction.date}</td>
                <td>${transaction.amount}</td>
                <td>{transaction.status}</td>
                <td><button onClick={() => alert(`Viewing details for ${transaction.id}`)}>View</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Transactions;
