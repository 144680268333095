// Import the required functions from the Firebase SDKs
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCxRYTDuVKCnguMs-AwndddqbDButimBX0",
  authDomain: "tietheday-a9ac9.firebaseapp.com",
  projectId: "tietheday-a9ac9",
  storageBucket: "tietheday-a9ac9.appspot.com",
  messagingSenderId: "182707862653",
  appId: "1:182707862653:web:7ec6d8334fe07749ecc514",
  measurementId: "G-68EX4921JB"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);

export { auth };
