import "./Dashboard.css";
import Header from "../Screens/Header"
import Home from "../Screens/home"
import Orders from "./order";
import { Route, Routes } from "react-router-dom";
import Transactions from "./Transactions";
import Profile from "./Profile";


const Dashboard = () => {
  // State to manage the active tab
 
  // Function to render content based on active tab
  

  return (
    <div className="App">
      {/* Header with navigation tabs */}
    
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>

    </div>
  );
};


export default Dashboard;
